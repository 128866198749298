@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/********************NAVBAR*******************/
.logo {
  display: flex;
  width: 14.25rem;
  max-height: 40px;
}
.custom-navbar {
  height: 90px;
}

/******************MENU LINKS*****************/
nav a.nav-link {
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

.nav-button {
  border-radius: 5px;
  background-color: #ffa500;
  text-decoration: none;
  border: 1px solid;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: 1rem;
}

.nav-button:hover {
  background-color: rgb(196, 130, 9);
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  display: flex;
  justify-content: center;
  max-width: 17.25rem;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

/*****************HEADER*******************/
.header-wraper {
  position: relative;
  background: url(background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-white);
  text-transform: uppercase;
}

/************TYPED-TEXT***************/
.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/*****************HEADER MAIN OFFER BUTTON*********************/
.btn-main-offer {
  text-decoration: none;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-red);
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}

/*******************ABOUT ME******************/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/*******************SERVICES******************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  border-radius: 7px;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

.services-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.services-text-title {
  font-weight: bold;
  padding-bottom: 30px;
}

/*******************PORTFOLIO******************/
.portfolio-wrapper {
  background: var(--primary-white);
  padding: 3rem 0;
  display: flex;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--primary-orange);
  /* padding: 0 0.5rem; */
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.portfolio-image-box p {
  display: flex;
  margin-bottom: 0;
  margin-left: 1rem;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
}

/*------------ OVERFLOW BOX ------------------*/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 15rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 6.6rem;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/*------------ OVERFLOW BOX ------------------*/
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  color: var(--primary-orange);
}

/*******************SKILLS******************/
.skills {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-white);
}

.skills h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-img {
  height: 5rem;
  width: 5rem;
  padding: 0.3rem;
}

.icon-img-fgv {
  height: 5rem;
  width: 9rem;
  padding: 0.3rem;
}

.icon-img-jump-park {
  height: 4rem;
  width: 13rem;
  padding: 0.3rem;
}

.icon-img-wrs {
  height: 5rem;
  width: 9rem;
  padding: 0.3rem;
}

.icon-img-synas {
  height: 7rem;
  width: 7rem;
  padding: 0.1rem;
  padding-bottom: 25px;
}

.skills .box {
  height: 5rem;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
}

.skills .square {
  position: relative;
  margin: 0.375rem;
  background: var(--primary-white);
  border-radius: 20%;
}

.skills icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*******************FOOTER******************/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding-bottom: 2rem;
  padding: 2rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

a.social {
  margin: 0 1rem;
  transition: transform 100ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

/*******************CONTACTS******************/
.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/*******************TEAM******************/
.team {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.team h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.team-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profile-cards-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.profile-card {
  width: 200px;
  text-align: center;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 150px; /* Defina a largura desejada */
  height: 150px; /* Defina a altura desejada */
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden; /* Para garantir que a imagem não ultrapasse o container */
}

.profile-image img {
  width: 100%; /* Para ocupar todo o espaço disponível */
  height: 100%; /* Para ocupar todo o espaço disponível */
  object-fit: cover; /* Para manter a proporção da imagem */
  object-position: center; /* Para centralizar a imagem */
  border-radius: 50%; /* Manter a borda arredondada */
}

.profile-info h2 {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.profile-info p {
  font-size: 1em;
  color: grey;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-team {
  width: 24px; /* ou o tamanho desejado para seus ícones */
  height: 24px;
  margin: 5px;
}

.icon-linkedin {
  background-image: url("./icons/github.svg");
}
.icon-github {
  background-image: url("./icons/github.svg");
}

/*******************INPUTS******************/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
  background: none;
  text-align: left;
}

.success-message {
  font-weight: 800;
  color: var(--primary-green);
  text-transform: uppercase;
}

/*******************MEDIA******************/
@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}
